import React from "react"
import MainPage from "@organic-return/foundation-gatsby-plugin-agility/src/MainPage"
import { useSiteContext } from "@organic-return/foundation-gatsby-plugin-agility/src/components/common/SiteContext"
import { ContactForm } from "@organic-return/foundation-react/src/components/Forms"

const RentalPage = () => {
  const siteContext = useSiteContext()
  return (
    <MainPage
      seoProps={{
        title: `Page not Found`,
      }}
    >
      <h1 className="mt-28">Page Not Found</h1>
      <ContactForm {...siteContext.globalContactFormProps} />
    </MainPage>
  )
}

export default RentalPage
